import React from "react";

import Layout from "../components/layout";
import Books from '../components/books';
import Posts from '../components/posts';

const IndexPage = () => {
  return (
    <Layout>
      <Books />
      <Posts />
    </Layout>
  );
};

export default IndexPage;
